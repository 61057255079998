.ql-editor a {
  color: rgb(2 122 82) !important;
  cursor: pointer;
  outline-color: #027a52 !important;
}

.ql-editor p {
  font-family: "Inter" !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #039861 !important;
}

.ql-snow.ql-toolbar button:hover .ql-fill {
  fill: #039861 !important;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
  stroke: #039861 !important;
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
  fill: #039861 !important;
}

.ql-editor em {
  font-style: italic !important;
}

.ql-editor a:hover {
  color: rgb(5 96 73) !important;
}

.ql-editor strong {
  font-weight: 700 !important;
}

.hide-quill-toolbar .ql-toolbar {
  display: none !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-snow .ql-tooltip {
  position: relative !important;
  transform: none !important;
  left: auto !important;
  top: auto !important;
}

.ql-snow .ql-tooltip::before {
  content: none !important;
  margin-right: 0px !important;
}

.ql-toolbar.ql-snow {
  padding: 8px;
  height: 30px !important;
  border: none !important;
  border-top: 1px solid #e8e8e8 !important;
  margin-top: 10px;
}

button.ql-underline {
  height: 23px !important;
  margin-top: -9px !important;
}

button.ql-italic {
  height: 23px !important;
  margin-top: -9px !important;
}

button.ql-bold {
  height: 23px !important;
  margin-top: -9px !important;
}

button.ql-clean {
  height: 23px !important;
  margin-top: -9px !important;
}

button.ql-list {
  height: 23px !important;
  margin-top: -9px !important;
}

button.ql-link {
  height: 23px !important;
  margin-top: -9px !important;
}

.ql-editor {
  padding: 4px !important;
  border-radius: 8px;
}

.ql-editor[contentEditable="true"] {
  border: none;
}

.ql-container .ql-editor.ql-blank::before {
  font-family: "Inter" !important;
  font-size: 13px;
  font-style: normal;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ec-agenda-full::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.ec-agenda-full {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.quill {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 8px;
}

.hide-quill-border {
  border: 1px solid white !important;
}

.focus-quill-border {
  border: 1px solid #32d591 !important;
  outline: 1px solid #32d591 !important;
}
.focus-quill-border:hover {
  border: 1px solid #12b778 !important;
  outline: 1px solid #12b778 !important;
}

.hide-quill-border:hover {
  border: 1px solid #e8e8e8 !important;
}

.ql-editor.ql-blank::before {
  margin-left: -7px !important;
}
